import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import React from "react";
import CustomDatePicker from "./CustomDatePicker";
import FilterOptions from "./FilterOptions";

export const REGEX = {
  NUMBERS: /^\d+$/,
  PHONE: /^[6-9]\d{9}$/,
  EMAIL:
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  DECIMALS: /^\d+(\.\d{1,2})?$/,
};

export const INPUT_TYPES = {
  DATE: "DATE",
  DROPDOWN: "DROPDOWN",
  FILE: "FILE",
  FILTER_OPTIONS: "FILTER_OPTIONS",
  INPUT: "INPUT",
  TEXT_AREA: "TEXT_AREA",
  NUM: "number",
};

export const RENDER_TYPES = {
  STRING: "string",
  DATE: "date",
  ARRAY: "array",
  OBJECT: "object",
  BOOLEAN: "boolean",
  LINK: "link",
};

export const getErrorKey = (_key) => `er_${_key}`;
export const getErrorText = (_key) => `er_txt_${_key}`;

export const CustomField = ({
  item = {},
  error,
  errorText,
  color = "primary",
  hardDisable,
  disabled: isDisable,
  value,
  itemData,
  onChange,
}) => {
  const {
    placeholder: _placeholder,
    isOptional,
    _key,
    shouldBeSame,
    inputType,
    overRideValues = {},
    maxLength,
    extraProps = {},
    extraData = [],
  } = item;
  const disabled = isDisable ? isDisable : hardDisable ? shouldBeSame : false;
  const onChangeValue = (event) => {
    onChange({
      overRideValues,
      maxLength,
      value: event.target.value,
      _key,
      event,
    });
  };
  const placeholder = _placeholder + (isOptional ? "" : "*");
  switch (inputType) {
    case INPUT_TYPES.INPUT:
      return (
        <TextField
          fullWidth
          color={color}
          size="small"
          error={error}
          helperText={errorText}
          label={placeholder}
          name={_key}
          disabled={disabled}
          variant="outlined"
          value={value ?? ""}
          onChange={onChangeValue}
          {...extraProps}
        />
      );

    case INPUT_TYPES.NUM:
      return (
        <TextField
          fullWidth
          color={color}
          size="small"
          error={error}
          helperText={errorText}
          label={placeholder}
          name={_key}
          disabled={disabled}
          variant="outlined"
          value={value ?? ""}
          type={"number"}
          onChange={onChangeValue}
          {...extraProps}
        />
      );

    case INPUT_TYPES.FILE:
      return (
        <>
          <label style={{ fontSize: "15px" }}>{placeholder}</label>

          <TextField
            fullWidth
            color={color}
            size="small"
            error={error}
            helperText={errorText}
            name={_key}
            type="file"
            disabled={disabled}
            variant="outlined"
            value={value ?? ""}
            onChange={onChangeValue}
            {...extraProps}
          />
        </>
      );

    case INPUT_TYPES.DATE:
      return (
        <CustomDatePicker
          color={color}
          disabled={disabled}
          error={error}
          _key={_key}
          errorText={errorText}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          extraProps={extraProps}
        />
      );

    case INPUT_TYPES.FILTER_OPTIONS:
      return (
        <FilterOptions
          color={color}
          disabled={disabled}
          error={error}
          _key={_key}
          errorText={errorText}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          extraData={extraData}
          extraProps={extraProps}
        />
      );

    case INPUT_TYPES.DROPDOWN:
      return (
        <FormControl
          disabled={disabled}
          error={error}
          color={color}
          fullWidth
          {...extraProps}
        >
          <InputLabel size="small" id={_key}>
            {placeholder}
          </InputLabel>
          <Select
            labelId={_key}
            size="small"
            name={_key}
            value={value ?? ""}
            label={placeholder}
            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
            onChange={onChangeValue}
          >
            {extraData?.map((_item, index) => (
              <MenuItem key={index} value={_item.value || _item}>
                {_item?.label || _item}
              </MenuItem>
            ))}
          </Select>
          {!!errorText && (
            <FormHelperText error={error}>{errorText}</FormHelperText>
          )}
        </FormControl>
      );

    case INPUT_TYPES.TEXT_AREA:
      return (
        <TextField
          fullWidth
          size="small"
          // multiline={!disabled}
          // minRows={5}
          // maxRows={10}
          InputProps={{
            rows: 5,
            multiline: true,
            inputComponent: "textarea",
          }}
          error={error}
          color={color}
          helperText={errorText}
          label={placeholder}
          name={_key}
          variant="outlined"
          value={value ?? ""}
          disabled={disabled}
          onChange={({ target }) => onChange({ value: target.value, _key })}
          {...extraProps}
        />
      );

    default:
      return null;
  }
};
