import { setAuthToken, setLoading } from "redux/actions";
import { store } from "redux/store";
import { ADMIN_SERVICE } from "utils";

export const loginRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  ADMIN_SERVICE.LOGIN(payload)
    .then(({ data, isSuccess }) => {
      if (isSuccess && onSuccess) {
        onSuccess();
        dispatch(setAuthToken(data.token));
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
