import { TablePagination, Typography } from "@mui/material";
import React from "react";

const CustomPagination = ({ paginationData, onChangePaginationData }) => {
  const { page, size, totalRecords } = paginationData;

  const onPageChange = (e, value) => {
    onChangePaginationData({ page: value + 1 });
  };

  const onRowsPerPageChange = (e) => {
    onChangePaginationData({ size: +e.target.value });
  };

  return (
    <Typography
      component={"tr"}
      sx={{
        pt: 3,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <TablePagination
        rowsPerPage={size}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        count={totalRecords}
        page={page - 1}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        sx={{ border: 0 }}
      />
    </Typography>
  );
};

export default CustomPagination;
