export const RESET = "RESET";
export const SET_LANGUAGES_DATA = "SET_LANGUAGES_DATA";
export const SET_LOADING = "SET_LOADING";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const SET_SCREEN_TITLE = "SET_SCREEN_TITLE";

export const setLanguagesData = (payload) => ({
  type: SET_LANGUAGES_DATA,
  payload,
});

export const setProfileData = (payload) => ({
  type: SET_PROFILE_DATA,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setAuthToken = (payload) => ({
  type: SET_AUTH_TOKEN,
  payload,
});

export const setScreenTitle = (payload) => ({
  type: SET_SCREEN_TITLE,
  payload,
});
