import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { ADMIN_SERVICE, EXPORT_DATA_URL } from "utils";

export const getDataRequest = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  ADMIN_SERVICE.GET_ALL(query)
    .then(({ data, isSuccess }) => {
      if (isSuccess && onSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getStaticDataRequest = ({ onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  ADMIN_SERVICE.GET_STATIC_DATA()
    .then(({ data, isSuccess }) => {
      if (isSuccess && onSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const exportData = ({ query }) => {
  const exportDataUrl = EXPORT_DATA_URL + `?${query}`;

  const link = document.createElement("a");
  link.href = exportDataUrl;
  link.target = "_blank";

  link.click();
};
