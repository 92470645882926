import React from "react";
import { Close } from "@mui/icons-material";
import { Button, IconButton, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function ModalWrapper({
  footerButtons,
  title = "",
  sx = {},
  childSx,
  color = "primary",
  background = "#f0fdf4",
  closeModal,
  children,
}) {
  return (
    <Modal
      open={true}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ outline: "none" }}
    >
      <div>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: { xs: "90vw", sm: "40vw" },
            width: "60%",
            maxHeight: "95vh",
            bgcolor: "background.paper",
            boxShadow: 4,
            borderRadius: "8px",
            ...sx,
          }}
        >
          <Typography
            color={color}
            id="modal-modal-title"
            component="div"
            sx={{
              display: "flex",
              background,
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1.5px solid",
              p: 2,
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {title}
            </Typography>
            <div style={{ display: "flex" }}>
              <IconButton onClick={closeModal}>
                <Close color={color} />
              </IconButton>
            </div>
          </Typography>
          <Typography
            id="modal-modal-description"
            component={"div"}
            // style={{ marginBottom: "60px" }}
            sx={
              childSx ?? {
                overflowY: "auto",
                height: { xs: "60vh", sm: "75vh" },
                p: 2,
              }
            }
          >
            {children}
          </Typography>
          {!!footerButtons && (
            <Typography
              id="modal-modal-footer"
              component="div"
              color={color}
              sx={{
                zIndex: 111,
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                borderTop: "1.5px solid",
                p: 2,
                background: "white",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              {footerButtons?.map((item, index) => (
                <Button
                  key={index}
                  color={color}
                  variant="contained"
                  sx={{ px: "20px" }}
                  {...item}
                >
                  {item?.title}
                </Button>
              ))}
            </Typography>
          )}
        </Box>
      </div>
    </Modal>
  );
}
