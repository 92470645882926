import { Link, Typography } from "@mui/material";
import { INPUT_TYPES, RENDER_TYPES } from "components";
import moment from "moment";
import { DETAIL_KEYS } from "screens/EnterData/constants";

export const VIEW_DATA_TABLE_NAME = "dssvolunteersdata";

export const getRenderCellItem = (data, item) => {
  switch (item.renderType) {
    case RENDER_TYPES.DATE:
      return moment(data).format("DD MMM YYYY");

    case RENDER_TYPES.BOOLEAN:
      return data ? "YES" : "NO";

    case RENDER_TYPES.ARRAY:
      return (
        <Typography
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 150,
          }}
        >
          {Array.isArray(data) ? data.join(" , ") : data}
        </Typography>
      );

    case RENDER_TYPES.LINK:
      return (
        <Typography
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 200,
          }}
        >
          {item.disabledFor === data ? (
            <>CV not uploaded</>
          ) : (
            <Link
              sx={{
                cursor: "pointer",
              }}
              href={data}
              target="_blank"
            >
              Download
            </Link>
          )}
        </Typography>
      );

    default:
      return (
        <Typography
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 200,
          }}
        >
          {data}
        </Typography>
      );
  }
};

export const getFilter = () => {
  return INPUT_TYPES.INPUT;
};

export const columns = Object.keys(DETAIL_KEYS).map((item) => ({
  _key: DETAIL_KEYS[item]._key,
  label: DETAIL_KEYS[item].label,
  filter: getFilter(),
  renderCell: (data) =>
    getRenderCellItem(data[DETAIL_KEYS[item]._key], DETAIL_KEYS[item]),
}));
