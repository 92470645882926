import { Routes, Route,Navigate  } from "react-router-dom";

import React from "react";
import User from "screens/dss_volunteers/User";
import ViewForm from "screens/dss_volunteers/ViewForm";
import { Login } from "screens/dss_volunteers/Login";
import { useSelector } from "react-redux";
import { setAuthToken } from "utils";
import { MainWrapper } from "mainWrapper";




export const Router = () => {
  const authToken = useSelector(({ common }) => common.authToken);
  setAuthToken(authToken);


  return (
    <Routes>
      <Route path="/" element={<User />}></Route>
     




     
            <Route
              path="/admin/login"
              element={authToken ? <Navigate to="/form/admin" replace /> : <Login/>}
            ></Route>
            <Route
              path="/admin"
              element={
                authToken ? (
                  <MainWrapper>
                  <Route path="/" element={<ViewForm/>}></Route>
                </MainWrapper>
                ) : (
                  <Navigate to="/form/admin/login" replace />
                )
              }
            ></Route>
    </Routes>
  );
};
