import { combineReducers } from "redux";
import commonReducer from "./common";
import tableReducer from "./table";

const rootReducer = combineReducers({
  common: commonReducer,
  table: tableReducer,
});

export default rootReducer;
