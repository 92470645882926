import { INPUT_TYPES, REGEX, RENDER_TYPES } from "components";

export const USER_KEYS = {
  NAME: { _key: "name", label: "Name" },
  FATHER_NAME: { _key: "fatherName", label: "Father's Name" },
  PHONE: { _key: "mobileNumber", label: "Phone Number" },
  PROFESSION: { _key: "profession", label: "Profession" },
  EXPERIENCE: { _key: "experience", label: "Experience (Years)" },
  EXPERTISE: { _key: "expertise", label: "Expertise" },
  EMAIL_ID: { _key: "emailId", label: "Email Address" },
  STATE: { _key: "state", label: "State" },
  CITY: { _key: "city", label: "City" },
  BLOCK: { _key: "block", label: "Block" },
  AREA_OR_ZONE: { _key: "areaOrZone", label: "Area/Zone" },
  TIMING_OF_SEWA: { _key: "timingForSewa", label: "Timing for Sewa" },
  VEHICLE_AVAILABLE: {
    _key: "vehicleAvailable",
    label: "Vehicle Available",
    renderType: RENDER_TYPES.DROPDOWN,
    extraData: [
      { label: "2 Wheeler", value: "2w" },
      { label: "4 Wheeler", value: "4w" },
      { label: "Other", value: "other" },
    ],
  },
};

export const USER_FIELDS = [
  {
    _key: USER_KEYS.NAME._key,
    placeholder: USER_KEYS.NAME.label,
    inputType: INPUT_TYPES.INPUT,
  },
  {
    _key: USER_KEYS.FATHER_NAME._key,
    placeholder: USER_KEYS.FATHER_NAME.label,
    inputType: INPUT_TYPES.INPUT,
  },
  {
    _key: USER_KEYS.PHONE._key,
    placeholder: USER_KEYS.PHONE.label,
    inputType: INPUT_TYPES.INPUT,
    regex: REGEX.NUMBERS,
  },
  {
    _key: USER_KEYS.PROFESSION._key,
    placeholder: USER_KEYS.PROFESSION.label,
    inputType: INPUT_TYPES.INPUT,
  },
  {
    _key: USER_KEYS.EXPERIENCE._key,
    placeholder: USER_KEYS.EXPERIENCE.label,
    inputType: INPUT_TYPES.INPUT,
  },
  {
    _key: USER_KEYS.EXPERTISE._key,
    placeholder: USER_KEYS.EXPERTISE.label,
    inputType: INPUT_TYPES.INPUT,
  },
  {
    _key: USER_KEYS.EMAIL_ID._key,
    placeholder: USER_KEYS.EMAIL_ID.label,
    inputType: INPUT_TYPES.INPUT,
    regex: REGEX.EMAIL,
  },
  {
    _key: USER_KEYS.STATE._key,
    placeholder: USER_KEYS.STATE.label,
    inputType: INPUT_TYPES.DROPDOWN,
  },
  {
    _key: USER_KEYS.CITY._key,
    placeholder: USER_KEYS.CITY.label,
    inputType: INPUT_TYPES.DROPDOWN,
  },
  {
    _key: USER_KEYS.BLOCK._key,
    placeholder: USER_KEYS.BLOCK.label,
    inputType: INPUT_TYPES.INPUT,
  },
  {
    _key: USER_KEYS.AREA_OR_ZONE._key,
    placeholder: USER_KEYS.AREA_OR_ZONE.label,
    inputType: INPUT_TYPES.INPUT,
  },
  {
    _key: USER_KEYS.TIMING_OF_SEWA._key,
    placeholder: USER_KEYS.TIMING_OF_SEWA.label,
    inputType: INPUT_TYPES.INPUT,
  },
  {
    _key: USER_KEYS.VEHICLE_AVAILABLE._key,
    placeholder: USER_KEYS.VEHICLE_AVAILABLE.label,
    inputType: INPUT_TYPES.DROPDOWN,
    extraData: [
      { label: "2 Wheeler", value: "2w" },
      { label: "4 Wheeler", value: "4w" },
      { label: "Other", value: "other" },
    ],
  },
];
