import { Button, Typography } from "@mui/material";
import React from "react";
import { CustomField, INPUT_TYPES } from "./CustomField";

export default function Header({
  variant = "h6",
  style = {},
  dropDown,
  title,
  buttons = [],
  titleStyle = {},
}) {
  return (
    <Typography
      component={"div"}
      className="hide-scrollbar"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        overflowX: "auto",
        gap: 8,
        py: "8px",
        ...style,
      }}
    >
      {!!title && (
        <Typography
          variant={variant}
          sx={{ display: "inline-flex", ...titleStyle }}
        >
          {title}
        </Typography>
      )}

      {!!dropDown ||
        (!!buttons.length && (
          <Typography
            sx={{
              flex: 1,
              justifyContent: {
                xs: buttons.length > 1 ? "space-between" : "flex-end",
                sm: "flex-end",
              },
              display: "flex",
              gap: 2,
            }}
          >
            {!!dropDown && (
              <Typography sx={{ width: { xs: "150px", sm: "200px" } }}>
                <CustomField
                  value={dropDown.value}
                  onChange={({ value }) => dropDown.onChange(value)}
                  item={{
                    inputType: INPUT_TYPES.DROPDOWN,
                    placeholder: dropDown.placeholder || "Select Language",
                    extraData: dropDown.data,
                  }}
                />
              </Typography>
            )}
            {buttons.map((buttonItem, index) => {
              return (
                <Button
                  key={index}
                  size="small"
                  variant="contained"
                  {...buttonItem}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {buttonItem.title}
                </Button>
              );
            })}
          </Typography>
        ))}
    </Typography>
  );
}
