import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Paper,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
} from "@mui/material";
import { useState } from "react";
import { loginRequest } from "./utils";
import LoginIcon from "../../assets/login-icon.svg";
import useDocumentTitle from "../useDocumentTitle";

export const Login = () => {
  useDocumentTitle("DSS Volunteers - Login");
  const [data, setData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onLogin = () => {
    loginRequest({
      payload: data,
      onSuccess: () => navigate("/", { replace: true }),
    });
  };

  return (
    <>
      <Typography
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
          onLogin();
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: { xs: "80vh", sm: "100vh" },
          justifyContent: "center",
          backgroundColor: { xs: "white", sm: "#1087dd1f" },
        }}
      >
        <Box className="login-card">
          <Typography
            variant="h4"
            sx={{
              mb: 2,
              fontSize: { xs: "30px", sm: "40px" },
              fontWeight: { xs: 500, sm: "bold" },
              color: "#1087dd",
            }}
            component="h1"
          >
            Log in
          </Typography>
          <Paper
            sx={{
              height: "100%",
              alignItems: "center",
              display: "flex",
              boxShadow: {
                xs: "none",
                sm: "0px 0px 3px rgb(0 0 0 / 70%)",
              },
            }}
          >
            <Grid container sx={{ p: { sm: "8px", md: "24px" } }} spacing={2}>
              <Grid
                item
                md={5}
                sm={12}
                xs={12}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <img
                  alt="login"
                  style={{ width: "200px", margin: "auto" }}
                  src={LoginIcon}
                />
              </Grid>
              <Grid
                item
                md={7}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <TextField
                  sx={{ my: { xs: 1.5, sm: 1 } }}
                  fullWidth
                  size="small"
                  label="Email"
                  variant="outlined"
                  name="email"
                  type="email"
                  value={data.email}
                  onChange={handleInputChange}
                />
                <TextField
                  sx={{ my: { xs: 1.5, sm: 1 } }}
                  fullWidth
                  size="small"
                  label="Password"
                  variant="outlined"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={data.password}
                  onChange={handleInputChange}
                />
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Show Password"
                  onChange={() => setShowPassword(!showPassword)}
                />
                <Button
                  sx={{ my: { xs: 1.5, sm: 1 } }}
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Typography>
    </>
  );
};
