import { Box, Card, CardContent } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Header from "components/HeaderV2";
import CustomTable from "components/CustomTable";
import CustomPagination from "components/CustomPagination";
import { exportData, getDataRequest } from "./utils";
import { queryString, successToast } from "utils";
import { USER_FIELDS, USER_KEYS } from "../User/constants";
import { SelectFieldsModal } from "components/CustomTable/SelectFieldsModal";
import { VIEW_DATA_TABLE_NAME, columns } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFields } from "redux/actions";
import CustomFilter from "components/CustomFilter";
import { INPUT_TYPES } from "components";
import { useNavigate, useSearchParams } from "react-router-dom";

const ViewForm = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    size: 10,
    total: 0,
    totalRecords: 0,
  });

  const [filter, setFilter] = useState({
    page: paginationData.page,
    size: paginationData.size,
  });

  const [searchParams] = useSearchParams();

  const [modalData, setModalData] = useState(null);
  const selectedFields = useSelector(
    ({ table }) => table.selectedFields[VIEW_DATA_TABLE_NAME]
  );
  const dispatch = useDispatch();

  if (!selectedFields) {
    dispatch(
      setSelectedFields({
        [VIEW_DATA_TABLE_NAME]: [
          USER_KEYS.NAME._key,
          USER_KEYS.PHONE._key,
          USER_KEYS.EMAIL_ID._key,
          USER_KEYS.PROFESSION._key,
          USER_KEYS.CITY._key,
        ],
      })
    );
  }

  const setFiltersData = (_state) => {
    navigate("/admin?" + queryString(_state), { replace: true });

    if (!_state.length) {
      setFilter({ page: paginationData.page, size: paginationData.size });
    } else {
      setFilter(_state);
    }
  };

  const setSearchParamsInFilter = useCallback(() => {
    let temp = {};
    for (const [key, value] of searchParams.entries()) {
      temp = {
        ...temp,
        [key]: value,
      };
    }

    setFilter((_state) => ({ ..._state, ...temp }));
  }, [searchParams]);

  useMemo(() => {
    setSearchParamsInFilter();
  }, [setSearchParamsInFilter]);

  useEffect(() => {
    getDataRequest({
      query: queryString(filter),
      onSuccess: (data) => {
        const { items, page, size, total, totalRecords } = data || {};

        setData(items || []);
        setPaginationData({ page, size, total, totalRecords });
      },
    });
  }, [filter]);

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header
            title={"Users"}
            style={{ width: "100%" }}
            buttons={[
              {
                title: "Export Data",
                variant: "outlined",
                disabled: !data?.length,
                onClick: () => {
                  exportData({
                    query: queryString(filter),
                    onSuccess: () => {
                      successToast("Data exported successfully!");
                    },
                  });
                },
              },
              {
                title: "Select Fields",
                variant: "outlined",
                onClick: () =>
                  setModalData({
                    data: USER_KEYS,
                    tableName: VIEW_DATA_TABLE_NAME,
                  }),
              },
            ]}
          />
          <CustomFilter
            onChange={setFiltersData}
            filtersData={filter}
            fields={USER_FIELDS.filter(
              ({ inputType }) => inputType !== INPUT_TYPES.FILE
            )}
          />

          <CustomPagination
            paginationData={paginationData}
            onChangePaginationData={(data) => {
              setPaginationData({ ...paginationData, ...data });
              setFilter({
                ...filter,
                page: data.page || filter.page,
                size: data.size || filter.size,
              });
            }}
          />

          <CustomTable
            columns={
              selectedFields?.length
                ? columns.filter((column) =>
                    selectedFields.includes(column._key)
                  )
                : columns
            }
            rows={data}
            paginationData={paginationData}
          />
        </CardContent>
      </Card>

      {!!modalData && (
        <SelectFieldsModal
          modalData={modalData}
          closeModal={() => setModalData(null)}
        />
      )}
    </Box>
  );
};

export default ViewForm;
