import { getErrorKey, getErrorText } from "components";
import { toast } from "react-hot-toast";
import isArray from "lodash/isArray";
export const successToast = (message) => toast.success(message);
export const errorToast = (message) => toast.error(message);
export const blue_500 = "#3b82f6";

export const validateFields = ({ _state, fields = [] }) => {
  let isValid = true;
  fields.forEach(({ isOptional, regex, _key }) => {
    if (isOptional) {
      return;
    }

    if (Array.isArray(_state[_key]) && _state[_key].length) {
      _state[getErrorKey(_key)] = false;
      _state[getErrorText(_key)] = "";
      return;
    }

    if (_state[_key] === undefined) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "This field can't be empty";
      return;
    }

    if (_state[_key] < 0) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "Please enter a valid value";
      return;
    }

    if (!((_state[_key] ?? "") + "")?.trim()?.length) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "Please enter a valid value";
      return;
    }
    if (regex && !regex.test(_state[_key])) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "Please enter a valid value";
      return;
    }

    _state[getErrorKey(_key)] = false;
    _state[getErrorText(_key)] = "";
    _state[_key] = ((_state[_key] ?? "") + "")?.trim();
  });
  return { isValid, _state };
};

export const queryString = (obj) => {
  var str = [];
  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      if (isArray(obj[p])) {
        const val = obj[p].map((item) => item.label || item);
        val.length &&
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(val));
        continue;
      }
      obj[p] !== undefined &&
        obj[p] !== "" &&
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }
  return str.join("&");
};
