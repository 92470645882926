import React from "react";

import Lottie from "react-lottie-player";

export const LottiePlayer = ({ animationData, style }) => {
  return (
    <Lottie
      loop
      animationData={animationData}
      play
      speed={0.9}
      style={{ width: 150, height: 150, ...style }}
    />
  );
};
