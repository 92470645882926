import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { setAuthToken, setProfileData } from "redux/actions";
import { ConfirmationModal } from "components/ConfirmationModal";
const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,

  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function CustomAppBar({ toggleDrawer, open }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme2 = useTheme();
  const SizeLessThenMd = useMediaQuery(theme2.breakpoints.down("md"));
  const [modalData, setModalData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeModal = () => {
    setModalData(null);
  };

  const onLogoutPress = () => {
    setModalData({
      title: "Logout",
      description: "Are you sure, you want to logout?",
      footerButtons: [
        {
          onClick: () => setModalData(null),
          sx: { px: "20px", mx: "8px" },
          title: "Cancel",
          variant: "outlined",
        },
        {
          onClick: () => {
            setModalData(null);
            dispatch(setProfileData({}));
            dispatch(setAuthToken(""));
            navigate("/admin/login", { replace: true });
          },
          sx: { px: "20px" },
          title: "Logout",
        },
      ],
    });
  };

  const isHome = window.location.pathname === "/";

  return (
    <AppBar
      elevation={2}
      sx={{
        // height: '48px',
        marginLeft: open ? drawerWidth : "0",
        width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
        background: "#1087dd",
        overflow: "hidden",
        zIndex: "999",
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          height: "auto",
          left: 0,
          p: 0,
          px: { xs: 1, sm: 3 },
          color: "black",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/*  hamburger menu icon  */}
        {SizeLessThenMd && isHome && (
          <IconButton
            onClick={toggleDrawer}
            sx={{
              // p: 0,
              display: {
                xs: "inline-flex",
              },
              color: "black",
            }}
          >
            <MenuIcon />
          </IconButton>
        )}

        <Box sx={{ flexGrow: 1 }} />
        <Tooltip title="User Details">
          <IconButton onClick={handleMenu}>
            <Avatar
              sx={{ bgcolor: "#a710dd8a" }}
              color="#1087dd"
              alt="Remy Sharp"
            />
          </IconButton>
        </Tooltip>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{ marginTop: "2.5rem", marginLeft: "-1rem" }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={handleClose}>
            <Link
              style={{
                color: "#000",
                textDecoration: "none !important",
              }}
              className="link"
              to="/profile"
            >
              <PersonIcon fontSize="small" sx={{ mr: 1 }} />
              Profile
            </Link>
          </MenuItem>
          <MenuItem onClick={() => console.log("logout")}>
            <Link
              style={{
                color: "#000",
                textDecoration: "none !important",
              }}
              className="link"
              to="/change-password"
            >
              <LockOpenIcon fontSize="small" sx={{ mr: 1 }} />
              Change Password
            </Link>
          </MenuItem> */}
          <MenuItem onClick={onLogoutPress}>
            <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography>Logout</Typography>
          </MenuItem>

          {!!modalData && (
            <ConfirmationModal closeModal={closeModal} modalData={modalData} />
          )}
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
