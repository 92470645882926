import { CheckOutlined } from "@mui/icons-material";
import { Button, Checkbox, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFields } from "redux/actions";

export const SelectFieldsModal = ({ closeModal, modalData, color }) => {
  const { data, tableName } = modalData || {};

  const selectedFields = useSelector(
    ({ table }) => table.selectedFields[tableName]
  );
  const dispatch = useDispatch();
  const [state, setState] = useState(selectedFields || []);
  const allSelected = state.length === Object.keys(data).length;

  if (!modalData || !modalData.tableName || !modalData.data) {
    return;
  }

  const footerButtons = [
    {
      onClick: () => closeModal(),
      sx: { px: "20px", mx: "8px" },
      title: "Close",
      variant: "outlined",
    },
    {
      onClick: () => {
        dispatch(
          setSelectedFields({
            [tableName]: state,
          })
        );
        closeModal();
      },
      sx: { px: "20px" },
      title: "Done",
    },
  ];

  return (
    <Modal
      open
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ outline: "none", overflow: "auto" }}
    >
      <Box
        component={"div"}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "40vw",
          width: { xs: "80%", sm: "30%" },
          bgcolor: "background.paper",
          boxShadow: 4,
          borderRadius: "8px",
          p: 2,
        }}
      >
        <Typography
          id="modal-modal-title"
          component="div"
          sx={{
            display: "flex",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" component="h2">
            Select
          </Typography>
          <Button
            startIcon={allSelected && <CheckOutlined />}
            color={color}
            variant={allSelected ? "contained" : "outlined"}
            sx={{ px: "20px" }}
            onClick={() => {
              if (allSelected) {
                return setState([]);
              }
              setState([...Object.keys(data).map((item) => data[item]._key)]);
            }}
          >
            {allSelected ? "All Selected" : "Select All"}
          </Button>
        </Typography>

        <Typography
          component={"div"}
          sx={{ height: 400, overflow: "auto", mt: 3, mb: 5 }}
        >
          {Object.keys(data).map((_key) => {
            return (
              <Typography key={_key} component={"div"}>
                <Checkbox
                  id={_key}
                  checked={state.includes(data[_key]._key)}
                  onChange={() => {
                    let _state = state;

                    const index = _state.findIndex(
                      (item) => item === data[_key]._key
                    );

                    if (index > -1) {
                      _state.splice(index, 1);
                    } else {
                      _state = [..._state, data[_key]._key];
                    }

                    setState([..._state]);
                  }}
                />
                <Typography htmlFor={_key} component={"label"}>
                  {data[_key].label}
                </Typography>
              </Typography>
            );
          })}
        </Typography>

        {!!footerButtons && (
          <Typography
            id="modal-modal-footer"
            component="div"
            color={color}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {footerButtons?.map((item, index) => (
              <Button
                key={index}
                color={color}
                variant="contained"
                sx={{ px: "20px" }}
                disabled={!state.length}
                {...item}
                onClick={item.onClick}
              >
                {item?.title}
              </Button>
            ))}
          </Typography>
        )}
      </Box>
    </Modal>
  );
};
