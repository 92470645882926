import { Button, Chip, Grid } from "@mui/material";
import React, { useState } from "react";
import {
  CustomField,
  getErrorKey,
  getErrorText,
  INPUT_TYPES,
} from "../CustomField";
import ModalWrapper from "../ModalWrapper";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import moment from "moment";
import { getStaticDataRequest } from "screens/ViewData/utils";

const FilterModal = ({
  filtersData,
  onChangeFilter,
  fields = [],
  closeModal,
}) => {
  const [data, setData] = useState(filtersData || {});

  const onChange = ({ value, _key }) => {
    setData((_state) => ({ ..._state, [_key]: value }));
  };

  const onApply = () => onChangeFilter(data);
  const onClear = () => onChangeFilter({});

  return (
    <ModalWrapper
      sx={{ width: "50%" }}
      childSx={{
        minHeight: "10vh",
        maxHeight: "70vh",
        p: 2,
        overflow: "auto",
      }}
      footerButtons={[
        {
          title: "Clear Filters",
          onClick: onClear,
          variant: "secondary",
          sx: { mr: 2 },
        },
        { title: "Apply Filters", onClick: onApply },
      ]}
      title="Filters"
      closeModal={closeModal}
    >
      <Grid container rowSpacing={2} spacing={1}>
        {fields.map((item) => {
          const { md, _key, dependentKey } = item;
          if (dependentKey && !data[dependentKey]) {
            return null;
          }
          return (
            <Grid key={_key} item md={md || 6} sm={12} xs={12}>
              <CustomField
                item={item}
                itemData={data}
                errorText={data[getErrorText(_key)]}
                error={data[getErrorKey(_key)]}
                value={data[_key]}
                onChange={onChange}
              />
            </Grid>
          );
        })}
      </Grid>
    </ModalWrapper>
  );
};

const CustomFilter = ({ filtersData = {}, onChange, fields = [] }) => {
  const [showModal, toggleModal] = useState(false);
  const [staticData, setStaticData] = useState({});
  const closeModal = () => toggleModal(false);
  const onShow = () => {
    toggleModal(true);
    getStaticDataRequest({
      onSuccess: (data) => {
        setStaticData(data);
      },
    });
  };

  const onChangeFilter = (...rest) => {
    closeModal();
    onChange(...rest);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flexWrap: "wrap", display: "flex", gap: 4 }}>
          {fields.map(({ inputType, placeholder, _key }) => {
            if (!filtersData[_key]) {
              return null;
            }

            const prefix = placeholder + ": ";
            const label =
              inputType === INPUT_TYPES.DATE
                ? moment(new Date(filtersData[_key])).format("DD MMM YYYY")
                : filtersData[_key];

            return (
              <Chip
                label={prefix + label}
                key={_key}
                onClick={onShow}
                color="primary"
                onDelete={() => onChange({ ...filtersData, [_key]: "" })}
              />
            );
          })}
        </div>
        <Button
          size="small"
          onClick={onShow}
          variant="contained"
          startIcon={<FilterAltIcon />}
        >
          Filters
        </Button>
      </div>
      {showModal && (
        <FilterModal
          onChangeFilter={onChangeFilter}
          closeModal={closeModal}
          fields={fields.map((item) =>
            staticData.hasOwnProperty(item._key)
              ? {
                  ...item,
                  extraData: staticData[item._key].map((item) => ({
                    label: item,
                    value: item,
                  })),
                }
              : item
          )}
          filtersData={filtersData}
        />
      )}
    </>
  );
};

export default CustomFilter;
