import { REHYDRATE } from "redux-persist";
import { RESET, SET_SELECTED_FIELDS } from "redux/actions";

const initialState = {
  selectedFields: {},
};

const tableReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_SELECTED_FIELDS:
      return {
        ...state,
        selectedFields: {
          ...state.selectedFields,
          ...action.payload,
        },
      };

    case REHYDRATE:
      return {
        ...initialState,
        ...((action.payload || {}).table || {}),
      };

    case RESET:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default tableReducer;
