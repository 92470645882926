// ** MUI Imports
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Paper } from "@mui/material";

const CustomTable = ({ rows, columns, paginationData }) => {
  return (
    <Paper
      sx={{
        minHeight: 500,
        width: "100%",
        overflow: "hidden",
        boxShadow: "none",
      }}
    >
      <TableContainer
        sx={{
          minHeight: 500,
          // maxHeight: 500,
          //  height: 500,
          mt: 2,
          overflow: "auto",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell key={"s.no."} sx={{ minWidth: 80 }}>
                S. No.
              </TableCell>
              {columns.map(({ label, minWidth }) => (
                <TableCell key={label} sx={{ minWidth: minWidth ?? 150 }}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((item, index) => (
              <TableRow
                hover
                key={item._id}
                sx={{ "&:last-of-type td, &:last-of-type th": { border: 0 } }}
              >
                <TableCell key={index}>
                  {!!paginationData
                    ? paginationData.size * (paginationData.page - 1) +
                      (index + 1)
                    : index + 1}
                </TableCell>
                {columns.map(({ label, renderCell, _key }) => (
                  <TableCell key={label} title={item[_key]}>
                    {renderCell(item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CustomTable;
