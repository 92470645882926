import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { isArray } from "lodash";

export default function FilterOptions({
  color,
  disabled,
  error,
  _key,
  errorText,
  onChange,
  placeholder,
  value,
  extraData,
  extraProps,
}) {
  const onChangeValue = (e, val) => {
    onChange({ value: val, _key });
  };

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      options={extraData}
      onChange={onChangeValue}
      value={isArray(value) ? value : []}
      color={color}
      size="small"
      defaultValue={[]}
      getOptionLabel={(option) => option?.label || ""}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          error={error}
          helperText={errorText}
          label={placeholder}
          placeholder={placeholder}
          {...extraProps}
        />
      )}
      {...extraProps}
    />
  );
}
