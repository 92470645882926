import { Close } from "@mui/icons-material";
import { IconButton, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { LottiePlayer } from "./LottiePlayer";
import SuccessJson from "../shared/lottie/success.json";

export const SuccessModal = ({ closeModal, modalData, color }) => {
  if (!modalData) {
    return;
  }

  const { title, description } = modalData;

  return (
    <Modal
      open
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ outline: "none" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "40vw",
          width: { xs: "80%", sm: "30%" },
          maxHeight: "95vh",
          bgcolor: "background.paper",
          boxShadow: 4,
          borderRadius: "8px",
          p: 2,
        }}
      >
        <Typography
          id="modal-modal-title"
          component="div"
          sx={{
            display: "flex",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
          <IconButton onClick={closeModal}>
            <Close color={color} />
          </IconButton>
        </Typography>

        <Typography
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <LottiePlayer animationData={SuccessJson} />
          <Typography
            id="modal-modal-description"
            sx={{
              py: 3,
              textAlign: "center",
            }}
          >
            {description}
          </Typography>
        </Typography>
      </Box>
    </Modal>
  );
};
