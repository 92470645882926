// mui Css
import "../src/styles/index.css";
import "../src/styles/mui.css";
import {
  StyledEngineProvider,
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import { Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import EnterData from "screens/EnterData/index.js";
import { MainWrapper } from "mainWrapper";
import { Login } from "screens/Login";
import { useSelector } from "react-redux";
import { setAuthToken } from "utils";

import { Router } from "./Routes/Router";
import ViewData from "screens/ViewData";

function App() {
  const authToken = useSelector(({ common }) => common.authToken);
  setAuthToken(authToken);

  const theme = createTheme({
    palette: {
      primary: { main: "#1087dd" },
      secondary: { main: "#1087dd1f" },
      neutral: { main: "#64748B", contrastText: "#888" },
      danger: { main: "#f44336" },
      white: { main: "#fff" },
    },
  });

  // useEffect(() => {
  //   setAuthToken(authToken);
  // }, [authToken]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <Routes>
            <Route path="/" element={<EnterData />}></Route>
            <Route
              path="/admin/login"
              element={authToken ? <Navigate to="/admin" replace /> : <Login />}
            ></Route>
            <Route
              path="/admin"
              element={
                authToken ? (
                  <MainWrapper>
                    <Route path="/" element={<ViewData/>}></Route>
                  </MainWrapper>
                ) : (
                  <Navigate to="/admin/login" replace />
                )
              }
            ></Route>
            <Route path="/form/*" element={<Router />} />
          </Routes>
          <Toaster
            position="top-right"
            reverseOrder={false}
            gutter={8}
            toastOptions={{
              duration: 3000,
              style: {
                background: "#363636",
                color: "#fff",
              },
              success: {
                duration: 3000,
                theme: {
                  primary: "blue",
                  secondary: "black",
                },
              },
            }}
          />
        </StyledEngineProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
