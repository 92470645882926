import React from "react";
import { Typography, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const Loader = () => {
  const { loading, message = "Loading..." } =
    useSelector(({ common }) => common.loading) || {};
  if (!loading) {
    return null;
  }
  return (
    <Typography
      component={"div"}
      sx={{
        top: 0,
        zIndex: 10000000,
        left: 0,
        right: 0,
        bottom: 0,
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: `rgba(0, 0, 0, 0.3)`,
      }}
    >
      <Typography
        component={"div"}
        sx={{
          width: "450px",
          height: "200px",
          borderRadius: "4px",
          backgroundColor: { sm: "white" },
        }}
      >
        <Typography
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
          <Typography
            ml={3}
            sx={{ display: { xs: "none", sm: "block" } }}
            variant="p"
            component="p"
          >
            {message}...
          </Typography>
        </Typography>
      </Typography>
    </Typography>
  );
};

export default Loader;
