import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { HTTP_SERVICE } from "utils";

export const getSkills = ({ onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_SKILLS()
    .then(({ data, isSuccess }) => {
      if (isSuccess && onSuccess) {
        onSuccess(
          data.map((item) => ({
            label: item,
            value: item,
          }))
        );
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getNationalities = ({ onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_NATIONALITIES()
    .then(({ data, isSuccess }) => {
      if (isSuccess && onSuccess) {
        onSuccess(
          data.map((item) => ({
            label: item,
            value: item,
          }))
        );
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getCountries = ({ onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_COUNTRIES()
    .then(({ data, isSuccess }) => {
      if (isSuccess && onSuccess) {
        onSuccess(
          data.map((item) => ({
            label: item.countryName,
            value: item.countryCode,
          }))
        );
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getStates = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_STATES(payload)
    .then(({ data, isSuccess }) => {
      if (isSuccess && onSuccess) {
        onSuccess(
          data.map((item) => ({
            label: item.stateName,
            value: item.stateCode,
          }))
        );
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getCities = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_CITIES(payload)
    .then(({ data, isSuccess }) => {
      if (isSuccess && onSuccess) {
        onSuccess(
          data.map((item) => ({
            label: item.cityName,
            value: item.cityName,
          }))
        );
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const submitForm = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.SUBMIT(payload)
    .then(({ isSuccess }) => {
      if (isSuccess && onSuccess) {
        onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
