import { Button, Card, Grid, Typography } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useState, useEffect } from "react";
import { USER_FIELDS, USER_KEYS } from "./constants";
import { CustomField, REGEX, getErrorKey, getErrorText } from "components";
import { submitUser as submitUserUtil, getStates, getCities } from "./utils";
import { validateFields } from "utils";
import useDocumentTitle from "screens/useDocumentTitle";
import { SuccessModal } from "components/SuccessModal";

export default function User() {
  useDocumentTitle("DSS Volunteers - Form");
  const [data, setData] = useState({});
  const { state } = data;
  const [cities, setCities] = useState([]);
  const [modalData, setModalData] = useState(null);
  const country = "in";
  const [states, setStates] = useState([]);

  useEffect(() => {
    getStates({
      payload: { countryCode: country },
      onSuccess: (data) => {
        console.log("States data:", data);
        setStates(data);
        setData((_state) => ({ ..._state, state: "" }));
      },
    });
  }, []);
  useEffect(() => {
    if (!country || !state) return;

    getCities({
      payload: {
        countryCode: country,
        stateCode: state,
      },
      onSuccess: (data) => setCities(data),
    });
  }, [country, state]);

  const closeModal = () => setModalData(null);

  const onChange = ({ _key, value, event }) => {
    const { target } = event || {};

    if (_key === USER_KEYS.PHONE._key) {
      if (
        value?.length > 10 ||
        (!REGEX.NUMBERS.test(value) && !!value.length)
      ) {
        return;
      }
    }

    if (_key === USER_KEYS.EXPERIENCE._key) {
      if (value?.length > 2 || (!REGEX.NUMBERS.test(value) && !!value.length)) {
        return;
      }
    }

    if (target?.files?.length) {
      return setData((_state) => ({
        ..._state,
        [_key]: value,
        [`${_key}-file`]: target.files[0],
      }));
    }
    setData((_state) => ({ ..._state, [_key]: value }));
  };

  const validateData = () => {
    const { _state, isValid } = validateFields({
      _state: {
        ...data,
      },
      fields: USER_FIELDS,
    });
    setData({ ..._state });

    return isValid;
  };

  const submitUser = () => {
    if (!validateData()) {
      return;
    }

    const formData = new FormData();
    USER_FIELDS.forEach(({ _key, inputType }) => {
      if (!data[_key]?.length) {
        return;
      }

      if (_key === USER_KEYS.STATE._key) {
        return formData.append(
          _key,
          states.find(({ value }) => value === data[_key]).label
        );
      }

      if (Array.isArray(data[_key])) {
        data[_key].forEach(({ value }) => {
          formData.append(_key, value);
        });
      } else {
        formData.append(_key, data[_key]);
      }
    });

    const payload = formData;
    const onSuccess = () => {
      setData({});
      setModalData({
        title: "Success",
        description:
          "Form submitted successfully. Thank you for providing your information.",
        footerButtons: [
          {
            onClick: () => setModalData(null),
            sx: { px: "20px", mx: "8px" },
            title: "OK",
          },
        ],
      });
    };

    submitUserUtil({ payload, onSuccess });
  };

  return (
    <Typography
      component={"div"}
      sx={{ background: "#1087dd1f", py: { xs: 0, sm: 4 }, minHeight: "100vh" }}
    >
      <Card
        sx={{
          p: 3,
          rowGap: 2,
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", sm: "50vw" },
          mx: "auto",
        }}
      >
        <Header title="User" variant="h4" titleStyle={{ fontWeight: 600 }} />
        <Grid container rowSpacing={2} spacing={3}>
          {USER_FIELDS.map((item, index) => {
            const { _key, md } = item;

            if (!_key) return null;

            if (_key === USER_KEYS.STATE._key) {
              item.extraData = states;
            }
            if (_key === USER_KEYS.CITY._key) {
              item.extraData = cities;
            }
            return (
              <Grid key={_key + index} item md={md || 6} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={item}
                  value={data[_key]}
                  onChange={onChange}
                  error={data[getErrorKey(_key)]}
                  errorText={data[getErrorText(_key)]}
                  extraData={
                    _key === USER_KEYS.STATE._key ? states : item.extraData
                  }
                />
              </Grid>
            );
          })}
        </Grid>

        <Typography component={"div"} sx={{ display: "flex", gap: 4 }}>
          <Button
            onClick={submitUser}
            color={"primary"}
            variant="contained"
            sx={{ px: "20px", mt: "20px", width: "100%" }}
          >
            Submit
          </Button>
        </Typography>
      </Card>

      {!!modalData && (
        <SuccessModal closeModal={closeModal} modalData={modalData} />
      )}
    </Typography>
  );
}
